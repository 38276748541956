<template>
  <div id="printArea">
    <CRow>
      <CCol sm="12">
        <CCard>
          <CRow>
            <CCol sm="3">
              <h4>Generated reading list</h4>
            </CCol>
            <CCol sm="8">
              <form v-on:submit.prevent="filterConsumption()">
                <CRow>
                  <CCol sm="3" class="pr-0">
                    <el-date-picker
                        @change="getBatchNo"
                        required
                        class="w-100"
                        v-model="params.month"
                        type="month"
                        placeholder="Select Month"
                        :format="$monthPickerFormat"
                        value-format="yyyy-MM"
                    />
                  </CCol>
                  <CCol sm="2" class="pr-0">
                    <select class="form-control" v-model="params.batch_no_id">
                      <option value="">All</option>
                      <option :value="batchNoList.id" v-for="batchNoList in batchNoLists">{{ batchNoList.batch_no }}
                      </option>
                    </select>
                  </CCol>
                  <CCol sm="2">
                    <button type="submit" class="btn btn-success w-100">Submit</button>
                  </CCol>
                  <CCol v-if="checkUsrPermissions(['Reading_all_delete'])" sm="4">
                    <button type="button" @click="deleteReading()" class="btn btn-danger w-100">Delete All for this
                      month
                    </button>
                  </CCol>
                </CRow>
              </form>
            </CCol>
          </CRow>
          <div class="table-responsive">
            <table class="table">
              <thead>
              <tr>
                <th class="font-weight-bold">SL</th>
                <th class="font-weight-bold">Account No</th>
                <!--                <th class="font-weight-bold">WRCode</th>-->
                <!--                <th class="font-weight-bold">MTRWDTH</th>-->
                <!--                <th class="font-weight-bold">NBill</th>-->
                <th class="font-weight-bold">PrevRead</th>
                <th class="font-weight-bold">PrevDate</th>
                <th class="font-weight-bold">PresRead</th>
                <th class="font-weight-bold">PresDate</th>
                <th class="font-weight-bold">Days</th>
                <th class="font-weight-bold">Consum</th>
                <th class="font-weight-bold">WaterBill</th>
                <th class="font-weight-bold">Excise</th>
                <th class="font-weight-bold">Total</th>
                <th class="font-weight-bold">MiCode</th>
                <th class="font-weight-bold">BatchNo</th>
                <!--                <th class="font-weight-bold">Status</th>-->
                <!--                <th class="font-weight-bold">EntBy</th>-->
                <th class="font-weight-bold">EntryDate</th>
                <!--                <th class="font-weight-bold">NE</th>-->
                <!--                <th class="font-weight-bold">MonthEndDate</th>-->
                <!--                <th class="font-weight-bold">EntryDate2</th>-->
                <!--                <th class="font-weight-bold">Year-Month</th>-->
              </tr>
              </thead>
              <tbody v-if="readings.length">
              <tr
                  v-for="(consumption, index) in readings"
                  :key="consumption.id"
              >
                <td scope="row">{{ meta.from + index }}</td>
                <td class="font-weight-bold">
                  <router-link :to="`/consumers/${consumption.customer_account_id}`">
                    {{ consumption.consumer_account_number }}
                  </router-link>
                </td>
                <!--                <td>{{ consumption.rate_id }}</td>-->
                <!--                <td>{{ consumption.mtr_wdth }}</td>-->
                <!--                <td>{{ consumption.non_domestic_bill }}</td>-->
                <td>{{ consumption.previous_read }}</td>
                <td>{{ consumption.previous_date | dateFormat }}</td>
                <td>{{ consumption.present_read }}</td>
                <td>{{ consumption.present_date | dateFormat }}</td>
                <td>{{ consumption.total_days }}</td>
                <td>{{ consumption.consumption }}</td>
                <td>{{ consumption.water_bill }}</td>
                <td>{{ consumption.excise }}</td>
                <td>{{ consumption.total }}</td>
                <td>{{ consumption.meter_inspector.mi_code }}</td>
                <td>{{ consumption.batch_no.batch_no }}</td>
                <!--                <td>{{ consumption.status }}</td>-->
                <!--                <td>{{ consumption.created_by }}</td>-->
                <td>{{ consumption.entry_date | dateFormat }}</td>
                <!--                <td>{{ consumption.ne }}</td>-->
                <!--                <td>{{ consumption.month_end_date }}</td>-->
                <!--                <td>{{ consumption.entry_date_2 }}</td>-->
                <!--                <td>{{ consumption.generate_year }}-{{ consumption.generate_month }}</td>-->
              </tr>
              </tbody>
              <tbody v-else>
              <tr>
                <td colspan="20">
                  <h5 class="text-center">Data Not Available.</h5>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import moment from "moment";
import axios from "axios";

export default {
  name: "ReadingGenerate",
  data: () => {
    return {
      batchNoLists: [],
      params: {
        batch_no_id: '',
        month: moment().clone().subtract(1, 'months').format('YYYY-MM'),
        page: 1,
        isStore: false
      },
      isMobile: Boolean,
      isMobileOnly: Boolean,
      breadcrumbs: [{text: "Home", path: "/dashboard", isActive: false}, {
        text: "Reading Generate",
        path: "/reading/generate",
        isActive: false
      }, {text: "Generated reading list", path: "", isActive: true}]
    };
  },

  computed: {
    ...mapGetters("Reading", [
      "readings",
      "rows",
      "perPage",
      "meta"
    ]),
  },
  methods: {
    fetchGenerateMonth() {
      axios.get(`/admin/fetch-generate-month/reading`).then(({data}) => {
        if (data.status) {
          const generatedMonth = data.data.generate_year + '-' + data.data.generate_month
          this.params.month = moment(generatedMonth).format('YYYY-MM');
        }

        this.getBatchNo()
        this.params.meterId = this.$route.params.id;
        this.$store.dispatch("Reading/getReadings", this.params);
      }).catch((err) => {
        console.log(err);
      }).finally(() => {
        this.$store.dispatch("Settings/loading", false);
      })
    },
    getBatchNo() {
      axios.get(`/admin/batch-no/lists`, {params: {month: this.params.month}}).then((res) => {
        this.batchNoLists = res.data;
      }).catch((err) => {
        console.log(err);
      });
    },
    filterConsumption() {
      this.$store.dispatch("Reading/getReadings", this.params);
    },
    deleteReading() {
      const formattedMonth = moment(this.params.month).format('MMMM-YYYY')
      this.$confirm({
        message: `Are you sure, want to delete ${formattedMonth} month reading data?`,
        button: {
          no: "No",
          yes: "Yes, Delete",
        },
        callback: (confirm) => {
          if (confirm) {
            this.$store.dispatch("Settings/loading", true);
            axios.get(`/admin/all-readings/delete`, {params: {month: this.params.month}}).then(({data}) => {
              if (data.status == 'error') {
                return this.$toastr.e(
                    data.status,
                    data.message,
                    data.title
                );
              }
              this.$toastr.s(
                  data.status,
                  data.message,
                  data.title
              );
              this.$store.dispatch("Reading/getReadings", this.params);
              this.batchNoLists = []
            }).catch((err) => {
              console.log(err);
            }).finally(() => {
              this.$store.dispatch("Settings/loading", false);
            })
          }
        },
      });
    },

    dateFormat(date) {
      return moment(date).format("DD-MM-YYYY, h:mm a");
    },
  },
  mounted() {
    this.fetchGenerateMonth()
  },
  created() {
    this.$store.dispatch("Config/setBreadCrumb", this.breadcrumbs);
    this.params.month = this.$route.query.month || moment().clone().subtract(1, 'months').format('YYYY-MM')
  },
};
</script>

<style scoped>
.card {
  border: none;
  padding: 15px 15px 0;
}

</style>

<style lang="scss" scoped>
div {
  h4 {
    font-size: 15px;
    font-weight: bold !important;
    color: #000;
  }

  .table {
    font-size: 14px;

    thead {
      tr {
        th {
          background: #f8f9fa;
          color: #000;
        }
      }
    }

    tbody {
      font-size: 13px;

      tr {
        color: #000;

        td {
          padding: 0.75rem 0 0.75rem 0.75rem;

          button {
            font-size: 13px;
          }
        }
      }
    }
  }
}
</style>
